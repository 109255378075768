.active{
    color: #FFBB00;
    padding-bottom: 10px;
    border-bottom: 3.5px solid #FFBB00;
    width: 100%;
}
.active img{
    border: 2.5px solid #FFBB00;
}

.withOpacity{
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.747);
    width: 100%;
    z-index: 100;
}
@media (200px <= width <= 600px){
    .withOpacity{
        background: rgba(0, 0, 0, 0.747);
    }
}