.admin-nav .active{
    color: #FFBB00;
    padding-bottom: 10px;
    border-bottom: none;
    width: 100%;


}

.admin-nav ul li{
    position: relative;
}

.admin-nav{
    background: #141414;
    position: fixed;
    top: 0;
    left: 0;
    width: 240px;
    height: 100%;
    padding: 5px 0;
    transition: all 0.5s ease;
}
.admin-body{
    width: calc(100% - 240px);
    margin-left: 240px;
    transition: all 0.5s ease;
    background-color: #1d1d1d;
    min-height: 100vh;
}

