
:root {
  --thm-font: "Roboto", sans-serif;
  --thm-font2: Arial;
  --thm-clr1: #FFBB00;
  --thm-clr2: #CBA135;
  --thm-clr3: #FFBD07;
  --thm-clr4: #000000B0;
  --text-clr: rgba(255, 255, 255, 0.863);
  
  
}

body{
font-family: var(--thm-font);
background: black;
color: white;
}

.banner{
background-image: url('../../../public/bbb2.jpg');
background-size: cover;
background-repeat: no-repeat;
background-position: center;

}
.banner div{
 display: flex ;
 flex-direction: column;
}



/* slider */
.slick-slide > div {
margin: 0 20px;

}
.slick-list {
margin: 0 -10px;
}

.arrow {
color: #DA29E0;
font-weight: 500;
}


.slick-dots button:before{
color:rgb(216, 206, 206)  !important;

}
.slick-dots li.slick-active button:before {
color:White !important;
}



/* @media (200px <= width <= 600px){

}  */


.ui-card{
position: relative;
overflow: hidden;
transition: all 0.3s ease-out;
}


.ui-card .centered{
position: absolute;
bottom: 0;
left: 0;
width: 100%;
text-align: center;
}

.ui-card .centered p{
transform: translateY(80px);

transition: all 0.3s ease-out 0.2s;
}
.ui-card .centered a{

transform: translateY(30px);
opacity: 0;
transition: all 0.3s ease-out 0.4s;
}

.ui-card:hover .centered p{
opacity: 1;
transform: translateY(0px);
}
.ui-card:hover .centered a{
opacity: 1;
transform: translateY(0px);
}


.card-img{
filter: brightness(0.9);
cursor: pointer;
}
.card-img:hover{
filter: brightness(0.7);
}

.arroww2{
font-size: 30px;
padding: 8px 10px;
border-radius: 50%;
background-color: rgba(255, 255, 255, 0.479);
color: white;
display: flex;
justify-content: center;
align-items: center;
position: absolute;
top: 50%;

z-index: 300;
cursor: pointer;
}
.arroww-right2{
right:120px;
}
@media (200px <= width <= 600px){
.arroww2{
  display: none;
}
.arroww-right2{
  right: 80px;
}
}
/* slider */


/* workshop */
.workshop{
background-image: url('https://res.cloudinary.com/dvbplh4z9/image/upload/v1669621453/Cooking%20Academy%20Assets/Rectangle_48_jfcl2k.svg');
background-size: cover;
background-repeat: no-repeat;
}



/* workshop */


/* slider 2 */

.work-img .slick-slide > div{
margin: 40px;
}
.work-img .slick-list{
margin: 0;
}

.slide3, .slide3 img{
cursor: pointer;
}
.work-img .slide3{
opacity: 0.8;
transform: scale(0.9);

}


.work-img .slide3-active{
opacity: 1;
transform: scale(1);
}
.work-img .card-slide{
background: rgba(255, 255, 255, 0.1);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.work-img .arrow3{
/* font-size: 30px;
padding: 8px 10px;
border-radius: 50%;
background-color: white; */
color: var(--thm-clr1);
display: flex;
justify-content: center;
align-items: center;
position: absolute;
bottom: -10%;
z-index: 300;
cursor: pointer;
}
.work-img .arrow3:hover{
  color: var(--thm-clr2);
}
.work-img .arrow-right3{
right:450px;
}
.work-img .arrow-left3{
left:450px;
}

@media (200px <= width <= 850px){
.work-img .slick-slide > div{
margin:0 10px;
}
.work-img .slide3-active{
  transform: scale(0.9);
}
.work-img .slide3{
opacity: 1;

}
.work-img .arrow3{
  font-size: 20px;
  bottom: -15%;
}
.work-img .arrow-right3{
  right:140px;
}
.work-img .arrow-left3{
  left:140px;
}
}
@media (1024px <= width <=1400px){
.work-img .arrow-right3{
  right:400px;
}
.work-img .arrow-left3{
  left:400px;
}
}

/*  */


/* slider 7 */



/* .shop .slide7{
opacity: .5;
transform: scale(0.9);

}

.shop .slide7-active{
opacity: 1;
transform: scale(1);
} */

.shop .arrow7{
font-size: 30px;
padding: 8px 10px;
border-radius: 50%;
background-color: white;
color: var(--thm-clr1);
display: flex;
justify-content: center;
align-items: center;
position: absolute;
top: 40%;
z-index: 300;
cursor: pointer;
}
.shop .arrow7:hover{
  color: var(--thm-clr2);
}
.shop .arrow-right7{
right:-50px;
}
.shop .arrow-left7{
left:-50px;
}

@media (200px <= width <= 750px){

.shop .arrow7{
  font-size: 20px;
  top: -50px;
}
.shop .arrow-right7{
  right:100px;
}
.shop .arrow-left7{
  left:100px;
}
}
@media (600px <= width <= 1000px){

}

/*  */


/* Services */
.zoom-card{
overflow: hidden;
/* -- Prevent flicker -- */
-webkit-backface-visibility: hidden;
-webkit-transform: translate3d(0, 0, 0);
}

.zoom-card img{
width: 100%;
-webkit-transition: all 1s ease;
-moz-transition: all 1s ease;
-ms-transition: all 1s ease;
-o-transition: all 1s ease;
transition: all 1s ease;
}
.zoom-card img:hover{
-webkit-transform: scale(1.02);
-moz-transform: scale(1.02);
-ms-transform: scale(1.02);
-o-transform: scale(1.02);
transform: scale(1.02);
}

.services .slick-slide > div{
margin: 0;


}
.services .slick-list{
margin: 0;

}
.custom-indicator{

bottom: -65px;
height: 150px;
/*  */
left: 350px;



}
.custom-indicator li{
width: 200px !important;
height: 100px !important;
filter: grayscale(100%);


}
.custom-indicator li.slick-active {
filter: grayscale(0%);
border: 2px #CBA135 solid;
height: 100px !important;
border-radius: 10px;
}
.custom-pagin{
height: 100px !important;
width: 200px !important;
object-fit: cover;
border-radius: 10px;
border: none;
padding-bottom: 3px;

}

@media (600px <= width <= 900px){
.custom-indicator li{
  width: 100px !important;
  height: 60px !important;
}
.custom-indicator{
  height: 100px;
  left: 0;
}
.custom-pagin{
  height: 60px !important;
  width: 100px !important;
}
.custom-indicator li.slick-active {
  height: 60px !important;
}
}

@media (200px <= width <= 600px){
.custom-pagin{
  display: none;
}
.custom-indicator li.slick-active {
  display: none;
}
}

/* Enquire Submit form*/

.enquire-form{
background-image: url('../../Assets/suscrb.webp');
background-size: cover;
background-repeat: no-repeat;

}
.enquire-form input[type=checkbox]{
accent-color: #FFBD07;
}

/* Enquire Submit form*/


/* recipe */
.recipe{
background-image: url('https://res.cloudinary.com/dvbplh4z9/image/upload/v1669621450/Cooking%20Academy%20Assets/Group_105_nr9xef.jpg');
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border-radius: 30px;
}

.dishes{
overflow: hidden;
/* -- Prevent flicker -- */
-webkit-backface-visibility: hidden;
-webkit-transform: translate3d(0, 0, 0);
}
.dishes img{
-webkit-transition: all 1s ease;
-moz-transition: all 1s ease;
-ms-transition: all 1s ease;
-o-transition: all 1s ease;
transition: all 1s ease;
}

.dishes img:hover{
-webkit-transform: scale(1.02);
-moz-transform: scale(1.02);
-ms-transform: scale(1.02);
-o-transform: scale(1.02);
transform: scale(1.02);
}

/* recipe */

/* feedback */
.feedback{
background-image: url('https://res.cloudinary.com/dxcgqtuhj/image/upload/v1704864710/Cooking%20Academy%20Assets/owzrt7qreptesumecli6.svg');
background-size: cover;
background-repeat: no-repeat;
}

.scroll-main {
-ms-overflow-style: none; 
scrollbar-width: none; 
overflow-y: scroll; 
transition: all 0.5s ease 0s;
cursor: pointer;
scroll-behavior: smooth;
}

.scroll-main::-webkit-scrollbar {
display: none;
scroll-behavior: smooth;
}


@media screen and (min-width: 850px){
.scroll-main:hover{
  animation: MoveUpDown 5s linear infinite;

}
@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-150px);
  }
}

}



/* Accordian */



.accordion-simple > .active {
display: block;
transition: all .5s ease-in-out;
-webkit-transition: all .5s ease-in-out;
}

.accordion__faq .inactive{
display: none;
transition: all .5s ease-in-out;
-webkit-transition: all .5s ease-in-out;
}

.acco-m > h3{
background: transparent;
border: none;
}
.acco-m > p{
background: transparent;
border: none;

}


.accordion__faq > div{
background-color: rgb(37, 37, 37);
border-radius: 5px;
cursor: pointer;
padding: 5px 20px;
margin-bottom: 15px;

}

.accordion__title{
color: white;
padding-bottom: 20px;
font-weight: 500;
}

.accordion__faq-heading{
display: flex;
justify-content: space-between;
align-items: center;

}
.accordion__faq-heading .active{
color: white;

}

.accordion__faq-heading h3{
color: white;

font-size: 16px;
padding-bottom: 10px;
padding-top: 10px;
}

.accordion__faq p {
margin: 0px;
font-size: 15px;
padding-bottom: 10px;
color: rgba(255, 255, 255, 0.507);
line-height: 1.4;

}


/* insta-bg */

.insta-bg{
background-image: url('../../Assets/instabg.webp');
background-size: cover;
background-repeat: no-repeat;

}
.insta .slick-slide > div{
margin: 0;
}
.insta .slick-list{
margin: 0;
}

.slide3, .slide3 img{
cursor: pointer;
}
.slide3{
opacity: .8;
transition: .5s;
}


.slide3-active{
opacity: 1;
}


.instta{
position: absolute;
top: 50%;
left: 50%;
margin-right: -50%;
transform: translate(-50%, -58%);
}



/* CHEFS SECTION */
#chefs span{
width: max-content;
font-size: 18px;
font-weight: 500;
text-align: center;
background-color: rgba(43, 42, 42, 0.603);
padding: 8px 25px;
border-radius: 5px;
z-index: 9999;
}


#chefs span p{
font-size: 15px;
}

@media (200px <= width <= 600px){
#chefs span{
    font-size: 10px;
    padding: 5px 15px;
}
#chefs span p{
  font-size: 7px;
}
}

#cheflabel1{
visibility: hidden;
opacity: 0;
transition: opacity 0.5s ease;
}
#cheftip1:hover #cheflabel1{
visibility: visible;
opacity: 1;
}
#cheflabel2{
visibility: hidden;
opacity: 0;
transition: opacity 0.5s ease;
}
#cheftip2:hover #cheflabel2{
visibility: visible;
opacity: 1;
}
#cheflabel3{
visibility: hidden;
opacity: 0;
transition: opacity 0.5s ease;
}
#cheftip3:hover #cheflabel3{
visibility: visible;
opacity: 1;
}
#cheflabel4{
visibility: hidden;
opacity: 0;
transition: opacity 0.5s ease;
}
#cheftip4:hover #cheflabel4{
visibility: visible;
opacity: 1;
}
#cheflabel5{
visibility: hidden;
opacity: 0;
transition: opacity 0.5s ease;
}
#cheftip5:hover #cheflabel5{
visibility: visible;
opacity: 1;
}
#cheflabel6{
visibility: hidden;
opacity: 0;
transition: opacity 0.5s ease;
}
#cheftip6:hover #cheflabel6{
visibility: visible;
opacity: 1;
}
#cheflabel7{
visibility: hidden;
opacity: 0;
transition: opacity 0.5s ease;
}
#cheftip7:hover #cheflabel7{
visibility: visible;
opacity: 1;
}
#cheflabel8{
visibility: hidden;
opacity: 0;
transition: opacity 0.5s ease;
}
#cheftip8:hover #cheflabel8{
visibility: visible;
opacity: 1;
}
#cheflabel9{
visibility: hidden;
opacity: 0;
transition: opacity 0.5s ease;
}
#cheftip9:hover #cheflabel9{
visibility: visible;
opacity: 1;
}






